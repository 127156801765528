/**
 * returns true if touch device
 */
export function isTouchDevice() {
	return 'ontouchstart' in window || navigator.maxTouchPoints;
}

/**
 * Test whether the device is handheld
 */
export function isHandheld() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

/**
 * Get accurate viewport width and height
 * Example: viewport().width
 */
export function viewport() {
  var e = window, a = "inner";
  if (!( "innerWidth" in window )) {
    a = "client";
    e = document.documentElement || document.body;
  }
  return { width : e[ a+"Width" ] , height : e[ a+"Height" ] }
}

/**
 * returns the absolute position of an element regardless of position/float issues
 * @param {HTMLElement} el - element to return position for 
 * @returns {object} { x: num, y: num }
 */
export function getPosition(el) {

  let x = 0;
  let y = 0;

  do {
    x += el.offsetLeft || 0;
    y += el.offsetTop || 0;
    el = el.offsetParent;
  } while (el);

  return { x: parseInt(x, 10), y: parseInt(y, 10) };
}

/**
 * Get the height of the entire page
 * Compatible with most (if not all) browsers
 */

export function pageHeight() {
  let body = document.body;
  let html = document.documentElement;
  return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
}

/**
 * Get the current scrollTop / pageYOffset value
 * Compatible with most (if not all) browsers
 */
export function scrollTop() {
  return document.documentElement.scrollTop || window.pageYOffset;
}

/**
 * Get the parameter from URL or string
 * e.i) getQueryString('tab', "/faq/?tab=first");
 */
export function getQueryString(field, url) {
  var href = url ? url : window.location.href;
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  var string = reg.exec(href);
  return string ? string[1] : null;
}

/**
 * Get the closest element by class or data attribute
 * 
 */
export function findAncestor(el, cls) {
  while ((el = el.parentElement) && (!el.classList.contains(cls) && !el.hasAttribute(cls)));
  return el;
}

/**
 * Get next element
 */
export function next(node, selector) {
  if (selector && document.querySelector(selector) !== node.nextElementSibling) {
    return null;
  }
  return node.nextElementSibling;
}

/**
 * Check the object whether it is empty or not
 * @param {Object} 
 */
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) || obj[key] !== "undefined")
      return false;
  }
  return true;
}

/**
 * Parse String to Object. e.i) URL parameters to Object
 * @param {String} 
 */
export function parseQueryObjectFromString(query) {
  var vars = query.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    if (typeof query_string[key] === "undefined") {
      if (decodeURIComponent(value) != "undefined") {
        query_string[key] = decodeURIComponent(value);
      }
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];

      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      if (decodeURIComponent(value) !== undefined) query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

/**
 * lead zero padding
 */
Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

