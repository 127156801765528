// Accordion //
import { next, getPosition } from '../helpers/helpers';

export default class Accordion {

    constructor(acc) {
        this.accs = acc;
        this.navItems = null;
        this.contentItems = null;
    }

    init(){
        this.registerEvents();
    }

    registerEvents() {
        this.accSetting(this.accs);
    }

    accSetting(acc) {
        this.navItems = [...acc.querySelectorAll('[data-acc-tab]')];
        this.contentItems = [...acc.querySelectorAll('[data-acc-content]')];
        this.navItems.map(function (navItem, i) {
            navItem.addEventListener("click", (e) => this.accClickHandler(e, i, acc));
            if (navItem.hasAttribute('data-acc-active')) {
                this.initOpenElement(navItem);
            }
        }, this);
    }

    initOpenElement(navItem){
        let content = next(navItem);
        this.setShowContent(navItem, content, this.accs,true);
    }

    accClickHandler(e, i, parent) {
        e.preventDefault();
        const nav = e.currentTarget;
        const content = next(nav);
        this.setShowContent(nav, content, parent, true);
    }

    setShowContent(nav, content, parent, init = false) {
        if (!init) return false;
        content.classList.toggle('active');
        nav.classList.toggle('active');
        parent.pnav = nav;
    }


}
