'use strict';
import 'babel-polyfill';
import Header from './modules/header';
import Accordion from './modules/accordion';
import Home from './pages/home';
import { relateShow } from './pages/recipe'

class App {
	constructor() {
        this.accs = [...document.querySelectorAll('[data-acc]')];
		this.routes();
	}
	routes (){
		let header = new Header();
        if(header.element) header.init();
		let home = new Home();
        if(home.element) home.init();

		this.accs.forEach( (acc) => {
			let accObj = new Accordion(acc);
			accObj.init();
		});
    }
}
window.App = new App();

/* recipe func */
relateShow();

