import Swiper from 'swiper';

export default class Home {

  constructor() {
    this.element = document.querySelector('.hero');
  }
  init() {
    var mySwiper = new Swiper('.swiper-container', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loopedSlides: 3,
      allowTouchMove: false,
      preventClicks: false,
      preventClicksPropagation: false,
      spaceBetween: 5,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.hero-button-next',
        prevEl: '.hero-button-prev',
      },
      breakpoints: {
        640: {
          spaceBetween: 0,
          allowTouchMove: true,
        }
      }
    })
  }
  
}

