// 各ejsページの data-category="",data-id=""の

import Scrollto from '../modules/scrollto';

export function relateShow() {

  const related = [
    {
      h1: "豆腐入り中華風コーンスープ",
      description: "サラッとしている無脂肪牛乳は中華味にもぴったり。\\n無脂肪牛乳とコーンの自然な甘味がぎゅっと詰まったスープです。",
      category: ['milk'],
      id: "01",
    },{
      h1: "かぼちゃのみそポタージュ",
      description: "無脂肪牛乳を使って、かぼちゃ本来のこっくりした旨味を生かしつつ、サラッとした口当たりに。他のお料理とも合わせやすいポタージュです。",
      category: ['milk'],
      id: "02",
    },{
      h1: "きのこのお手軽ビーフストロガノフ",
      description: "フライパンひとつでできる簡単レシピ。無脂肪牛乳のサラッとした甘みとコクが、粒マスタードやみそと相性抜群。ご飯が進む美味しさです。",
      category: ['milk'],
      id: "03",
    },{
      h1: "いちごのクリームチーズ\\nアイスクリーム",
      description: "クリーミーなコクとフワッと溶ける滑らかさが後引く美味しさ。好みのジャムでもおためしを。",
      category: ['cream'],
      id: "04",
    },{
      h1: "にんじんのたらこチーズあえ",
      description: "余熱でとろけたクリームチーズとたらこの旨味で、にんじんが箸が止まらない美味しさに。",
      category: ['cream'],
      id: "05",
    },{
      h1: "クリームチーズタルタルの\\n.pチキン南蛮",
      description: "クリームチーズで作るクリーミーでコクがある上品な味わいのタルタルソースです。マヨネーズを使わないのでグッとヘルシー。",
      category: ['cream'],
      id: "06",
    },{
      h1: "柚子風味のなめらかヨーグルトゼリー",
      description: "濃厚でクリーミーな町村農場の「のむヨーグルト」ならでは。\\n生クリームや卵白を加えなくてもムースの様ななめらかさ。",
      category: ['drink_yogurt'],
      id: "07",
    },{
      h1: "インド風ヨーグルトチキンカレー",
      description: "フライパンで煮込まず作るお手軽カレーです。\\n飲むヨーグルトでマリネすると、乳酸菌効果で鶏肉が柔らかくジューシーになります。",
      category: ['drink_yogurt'],
      id: "08",
    },{
      h1: "クリームチーズ入りネギと桜エビの大根餅",
      description: "カリっもちっの大根餅の中に、とろっとクリーミーなクリームチーズが顔を出します。",
      category: ['cream'],
      id: "09",
    },{
      h1: "白麻婆豆腐春雨鍋",
      description: "無脂肪牛乳で白湯風に。コクと旨味たっぷりの鶏ガラスープのようになります。",
      category: ['milk'],
      id: "10",
    }
    ,{
      h1: "きなこプリン",
      description: "無脂肪牛乳は和のスイーツにもぴったり。ミルク感が強すぎないので、きなこの香りや風味をうまく引き立ててくれます。",
      category: ['milk'],
      id: "11",
    }
    ,{
      h1: "鮭とはんぺんのふわふわロールキャベツ　和風クリーム煮",
      description: "和風だしと無脂肪牛乳の相性は抜群。片栗粉を使う、手軽で低カロリーなクリーム煮です。",
      category: ['milk'],
      id: "12",
    }
    ,{
      h1: "モツァレラの味噌漬け",
      description: "町村農場のモツァレラチーズならではのしっかりしたミルクの旨味がさらにギュッと凝縮され、みその風味と相まって味わい深い1品です。",
      category: ['mozzarella'],
      id: "13",
    }
  ]

	//関連レシピが3つに満たない場合の穴埋め要員
	const emptyRlated = {
		"01" : ["02","03","13"],
		"02" : ["01","08","10"],
		"03" : ["13","02","10"],
		"04" : ["11","09","07"],
		"05" : ["12","06","09"],
		"06" : ["08","05","09"],
		"07" : ["08","04","11"],
		"08" : ["07","06","03"],
		"09" : ["04","05","06"],
		"10" : ["12","01","03"],
		"11" : ["07","04","12"],
		"12" : ["01","02","13"],
		"13" : ["11","10","05"],
	}


  /**
   **/
  const st = new Scrollto();
  let pagetop = document.querySelector('.page-top a');
  pagetop.addEventListener('click', function(e){
    e.preventDefault();
    st.animate(0, 400);
  });


  /**
   * 配列を返します。
   * @module extraction
   * @param レシピの配列を渡す
   * @return レシピオブジェクトから自分以外のオブジェクトを新しい配列として返します。自分のdata-idとレシプオブジェクトのidが同じであればそれは新しい配列には入れない
 */

  const extraction = (array) => {
    let data;
    let length;
    const cateGroup = [];
    const getId = document.getElementById('recipe').getAttribute('data-id');
    const getCate = document.getElementById('recipe').getAttribute('data-category');


    data = array === undefined ? related: array;
    for(let i = 0; i < data.length; i++) {

      if( getId !== data[i].id ) { // ページのdata-num objのidが同じでなかったら処理開始
        for( let j = 0; j < data[i].category.length; j++ ) {
          if( data[i].category[j] === getCate ) {
            cateGroup.push(data[i]);
          }
        }
      }
    }
    return array = cateGroup;
  }

  /**
     * 改行に変換
     * @module textChange
     * @param  string
     * @return 改行に変換。データーに入っているエスケープ文字にによって改行を変更している
   */
  const textChange = (text) => {
    var tx = text;
    if( tx.match(/\\n.p/g)){
      tx = tx.replace(/\\n.p/g, '<br class="pc_parts">');
    } else if(tx.match(/\\n.s/g)) {
      tx = tx.replace(/\\n.s/g, '<br class="sp_parts">');
    } else if(tx.match(/\\n/g)) {
      tx = tx.replace(/\\n/g, '<br>');
    }
    return tx;
  }

  /**
     * htmlを返している
     * @module textChange
     * @param  string
     * @return 関連性のあるレシピとして、レシピオブジェクトで自身以外のオブジェクトをhtmlとして返している。最大3つまで。またカテゴリは１つの想定で実装2つ以上は想定外
   */
  const relatedFunc = ()=> {
    let dataRelated = related;
    const insertTarget = document.querySelector('#relatedArea');

    if(!dataRelated || insertTarget === null) { return };


    let html = "";


		dataRelated = new Array(); //ランダム表示なし


/*
		// ランダム表示で使用 --------------------
    dataRelated = extraction(dataRelated);
		//取り出す範囲(箱の中)を末尾から狭める繰り返し
    for(let i = dataRelated.length -1;i>0;i--){
      //乱数生成を使ってランダムに取り出す値を決める
      let r = Math.floor(Math.random()*(i+1));
      //取り出した値と箱の外の先頭の値を交換する
      let tmp = dataRelated[i];
      dataRelated[i] = dataRelated[r];
      dataRelated[r] = tmp;
    }
*/

		/*
		関連レシピが3つに満たない場合、3つになるように不足分を埋める
		※ emptyRlatedの指定に沿って、dataRelatedに関連レシピを追加
		*/
		const getId = document.getElementById('recipe').getAttribute('data-id');
		//if(dataRelated.length < 3){
			for(var key in emptyRlated[getId]){
				//表示させる関連レシピID
				const targetId = emptyRlated[getId][key];
				//targetIdを持つ配列を取り出す
				const target = related.filter(function(object) {
					return object.id == targetId;
				}).shift();

				dataRelated.push(target);
			}
		//}//if(idx < 3)

		for(let idx in dataRelated){
			let myData = dataRelated[idx];

      html += `<a href="${myData.id}.html" onclick="_gaq.push(['_trackEvent', 'click', 'page_view', 'recipe_detailRelated--detail${myData.id}']);">`,
      html += '<div><img src="/user_data/packages/default/img/recipe_img/recipe_menu_mv_'+myData.id+'.jpg"></div>',
      html += '<h4>'+textChange(myData.h1)+'</h4>',
      html += '<div class="descr"><p>'+ textChange(myData.description)+'</p></div>',
      html += '</a>'
		}

    insertTarget.insertAdjacentHTML('beforeend', html);
  }




  relatedFunc();
}