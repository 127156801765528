
import { viewport, scrollTop, getPosition } from '../helpers/helpers';

export default class Header {

  constructor() {
    this.element = document.querySelector('.header');
    this.body = document.querySelector('body');
    this.main = document.querySelector('.wrap');
    this.navItems = document.querySelectorAll('#header nav a');
    this.bmLogo = document.getElementById('logoBM');
    this.mainIsInView = false;
    this.headerIsFixed = false;
    this.headerIsRemove = false;
    this.hamburger = document.querySelector('[data-mobile-btn]');
    this.btnSearch = document.querySelector('[data-search-btn]');
    this.searchForm = document.querySelector('.header__nav__utility__item--form');
    // this.btnPagetop = document.querySelector('.btn-pagetop');
    this.mobileNavIsOpen = false;
    this.stp = 0;
  }


  init() {
    this.onScroll();
    this.scrollHandle = this.onScroll.bind(this);
    window.addEventListener('scroll', this.scrollHandle);
    // console.log(getPosition(this.main));
    
    this.hamburger.addEventListener('click', this.hamburgerHandle.bind(this));
    this.btnSearch.addEventListener('click', this.btnSearchHandle.bind(this));
    // this.btnPagetop.addEventListener('click', this.clickPagetop.bind(this));
  }

  onScroll(e) {
    const st = scrollTop();
    if (this.main) this.mainIsInView = st > getPosition(this.main).y;
    this.headerIsFixed = this.element.classList.contains('fixed');

    if (this.mainIsInView && !this.headerIsFixed) {
      this.element.classList.add('fixed');
    } else if (!this.mainIsInView && this.headerIsFixed) {
      this.element.classList.remove('fixed');
    }
    if (this.mainIsInView) {
      (st > this.stp) ? this.removeHeader() : this.defaultHeader();
      this.stp = scrollTop();
    }
  }

  defaultHeader() {
    if (this.headerIsRemove) {
      this.headerIsRemove = false;
    }
  }
  removeHeader() {
    if (!this.headerIsRemove) {
      this.headerIsRemove = true;
    }
  }

  // mobile
  hamburgerHandle() {
    this.mobileNavIsOpen ? this.closeMobileNav() : this.openMobileNav();
  }
  openMobileNav() {
    this.body.classList.add('mobile-nav-open');
    this.mobileNavIsOpen = true;
  }
  closeMobileNav() {
    this.body.classList.remove('mobile-nav-open');
    this.mobileNavIsOpen = false;
  }

  //pagetop
  clickPagetop() {
    this.st.animate(0, 1000);
  }

  btnSearchHandle() {
    this.searchForm.classList.toggle('active');
  }


}

